import Modal from '../../components/Modals'
import {Button, Row, Col, Form, Table} from 'react-bootstrap'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'
import { Rating } from 'react-simple-star-rating'
import {useState, useEffect} from 'react'
import api from '../../utils/Api'
import {DoctorSchedule, BranchSchedule} from './components/Schedules'
import moment from 'moment'

export default function DoctorIframe () {
  // const [searchParams] = useSearchParams()
  // const id = searchParams.get('name')
  // const token = searchParams.get('token')
  const {code} = useParams()
  const [data, setData] = useState<any>([])
  const [reviewCompleted, setReviewCompleted] = useState(false)
  const [d, setD] = useState(new Date)

  useEffect(()=>{
    api.request('iframe/branch', (r,s)=>{
      if (!s) return
        setData(r)
    }, {branchCode: code, startDate: moment(d).startOf('month').toDate(), endDate: moment(d).endOf('month').toDate()})
  }, [d])

  return (
    <>
      <BranchSchedule onMonthChange={(d: Date)=>setD(moment(d).add(1, 'week').toDate())} data={data} />
    </>
  )
}
